import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import * as ROUTES from "../constant/route";
import { useHistory } from "react-router";
const BottomNav = ({ setBottomnav }) => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="ListView"
          onClick={() => setBottomnav(1)}
          icon={<RestoreIcon />}
        />
        <BottomNavigationAction
          label="Map"
          onClick={() => setBottomnav(2)}
          icon={<LocationOnIcon />}
        />
        <BottomNavigationAction
          label="Alert"
          onClick={() => setBottomnav(3)}
          icon={<BusAlertIcon />}
        />
        <BottomNavigationAction
          label="Notification"
          onClick={() => setBottomnav(4)}
          icon={<NotificationsActiveIcon />}
        />
        <BottomNavigationAction
          label="Setting"
          onClick={() => setBottomnav(5)}
          icon={<SettingsIcon />}
        />
      </BottomNavigation>
    </Box>
  );
};
export default BottomNav;
