import React from "react";
import { Box, Typography, Card, Button, Icon } from "@mui/material";

import { withFirebase } from "../../Firebase";
import * as ROUTES from "../../constant/route";
import { useHistory } from "react-router";
import LogoutIcon from "@mui/icons-material/Logout";
const SettingControl = ({ firebase }) => {
  const history = useHistory();
  const handleLogout = () => {
    firebase.doSignOut();
    localStorage.removeItem("User");
    history.push(ROUTES.LANDING);
  };

  return (
    <Box style={{ padding: 20, marginTop: -150 }}>
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          style={{ height: 450, margin: 5 }}
        >
          <Button
            style={{
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            onClick={() => handleLogout()}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 205 }}
            >
              <Icon style={{ height: 50, marginTop: 15 }}>
                <LogoutIcon />
              </Icon>
              <Typography>Log Out</Typography>
            </Box>
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default withFirebase(SettingControl);
