import * as React from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as ROUTES from "../constant/route";
// import { setUserDevice } from "../redux/action/device_action";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
const NavBar = ({ data }) => {
  console.log("data", data);
  const history = useHistory();
  // const monthlyClicked = () => {
  //   history.push({
  //     pathname: ROUTES.REPORT,
  //     state: device,
  //   });
  // };
  // const dailyClicked = () => {
  //   history.push("/Mobiledailyreport");
  // };
  const logoutClicked = () => {
    // localStorage.removeItem("Device");
    // localStorage.removeItem("Login");
    // setUserDevice(null);
    // history.push("/login");
  };
  const responsive = useMediaQuery("(max-width:500px)");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          {responsive ? (
            <IconButton
              onClick={() => history.push(ROUTES.LANDING)}
              sx={{ flexGrow: "1" }}
              style={{ marginRight: 300 }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <Typography sx={{ flexGrow: "1" }}></Typography>
          )}
          <PopupState
            variant="popover"
            popupId="demo-popup-menu"
            style={{ marginRight: 100 }}
          >
            {(popupState) => (
              <React.Fragment>
                <IconButton variant="text" {...bindTrigger(popupState)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() =>
                      history.push({
                        pathname: ROUTES.DAILY_REPORT,
                        state: data,
                      })
                    }
                  >
                    Daily Report
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      history.push({
                        pathname: ROUTES.REPORT,
                        state: data,
                      })
                    }
                  >
                    Monthly Report
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     ...state,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     setUserDevice: (device) => dispatch(setUserDevice(device)),
//   };
// };
export default NavBar;
