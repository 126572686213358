import axios from "axios";
import { GET_DEVICE_URL } from "../../utils/url";
import { SET_DEVICE } from "../constant/action_type";

export const setdevice = (devices) => {
  return {
    type: SET_DEVICE,
    payload: devices,
  };
};

export const getdevice = (id) => {
  return (dispatch) => {
    return axios
      .get(GET_DEVICE_URL + id)
      .then((response) => {
        dispatch(setdevice(response.data));
      })
      .catch((err) => {
        throw err;
      });
  };
};
