import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";

const Popup = ({ device, geo }) => {
  console.log(geo);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "white",
        opacity: 0.75,
        padding: 10,
        width: 500,
        height: 100,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          Engine/Registration Number:{" "}
        </Typography>
        <Typography color="black">{device.registration_number}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          IGNITION:
        </Typography>
        <Typography color="black">{geo.acc}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          CHASIS NUMBER:
        </Typography>
        <Typography color="black">{device.chasis_number}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          LAST UPDATE:
        </Typography>
        <Typography color="black">
          {device.update_time
            ? moment(geo.update_time).format("MMMM Do YYYY, h:mm:ss a")
            : null}
        </Typography>
      </Box>
    </Box>
  );
};

export default Popup;
