import React from "react";
import { Box, Typography, Icon } from "@mui/material";
import Poweroption from "./card";
import { useLocation } from "react-router-dom";

const Controll = () => {
  const location = useLocation();
  const device = location.state;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        style={{ backgroundColor: "#F18905", height: 300, width: "100vw" }}
      >
        <Typography style={{ padding: 10, marginTop: 50 }} variant="h4">
          Control Panel
        </Typography>
      </Box>
      <Box>
        <Poweroption power={device} />
      </Box>
    </Box>
  );
};

export default Controll;
