import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "./header";
import Container from "./container";
import { connect } from "react-redux";
import { grey } from "@mui/material/colors";
import { getdevice } from "../../redux/action/device_action";
const ListView = ({ devices, authuser, getdevice }) => {
  // console.log(devices);
  useEffect(() => {
    if (devices.length == 0 && authuser != null) {
      getdevice(authuser._id);
    }
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: grey[300], height: "100%", width: "100vw" }}
    >
      <Box display="flex" position="fixed" justifyContent="center">
        <Header />
      </Box>

      <Box style={{ marginTop: 120 }}>
        {devices.map((item, index) => {
          return (
            <Box key={index} style={{ margin: 10 }}>
              <Container data={item} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getdevice: (id) => dispatch(getdevice(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
