import * as app from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBhhaQbnF4kYJzXyh334B05lgANfYN5gc4",
  authDomain: "sino-22490.firebaseapp.com",
  databaseURL: "https://sino-22490.firebaseio.com",
  projectId: "sino-22490",
  storageBucket: "sino-22490.appspot.com",
  messagingSenderId: "496624604132",
  appId: "1:496624604132:web:b7333483a69cffea252df1",
  measurementId: "G-QKNPKP2VT9",
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.database();
    this.provider = new app.auth.GoogleAuthProvider();
    this.provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

    // this.messaging = app.messaging();
  }

  createUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };

  auth = () => {
    return this.auth;
  };

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) => {
    return this.auth.currentUser.updatePassword(password);
  };

  currentUser = () => this.auth.currentUser;

  idToken = () => this.auth.currentUser.getIdToken(true);

  googleSignIn = () => this.auth.signInWithRedirect(this.provider);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      //   url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
      url: "http://localhost:3000",
    });

  userDevices = (uid) =>
    this.db.ref("devices").orderByChild("uid").equalTo(uid);

  singleDeviceRef = (id) => this.db.ref().child("devices").child(id);
}

export default Firebase;
