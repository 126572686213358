import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import useCollapse from "react-collapsed";
import { ArrowDropDownCircle, ArrowDropUpRounded } from "@mui/icons-material";
import Collapse from "./collapse";
const ReportTable = ({ monthlydata }) => {
  console.log(monthlydata);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ height: "100%", width: "100%" }}
    >
      {monthlydata.map((item, index) => {
        return (
          <Box>
            <Collapse item={item} index={index} />
          </Box>
        );
      })}
    </Box>
  );
};

export default ReportTable;
