export const SET_USER = "SET_USER ";

export const SET_DEVICE = "SET_DEVICE";
export const AUTH_USER = "AUTH_USER";
export const SHARED_DEVICE = "SHARED_DEVICE";
const vehicles = [
  "Car",
  "Bike",
  "Micro-Bus",
  "Bus",
  "Truck",
  "CNG",
  "Water-Vessel",
  "Tractor",
];

const getVehicleType = (index) => {
  return vehicles[index - 1];
};

export { getVehicleType };
