/* global google */
import React from "react";
import { Box } from "@mui/material";
import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import LocationMarker from "./locationMarker";

const GMap = ({ device }) => {
  const containerStyle = {
    height: "100vh",
    width: "100vw",
  };

  const center = {
    lat: device.geo.lat,
    lng: device.geo.lng,
  };
  return (
    <Box>
      <LoadScript googleMapsApiKey="AIzaSyBBeH5xJ1bibGLYendPv967p6iXxuo9l58">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
          <LocationMarker device={device} />

          <TrafficLayer />
        </GoogleMap>
      </LoadScript>
    </Box>
  );
};

export default GMap;
