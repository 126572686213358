import React, { useState } from "react";
import { Box, TextField, Button, Typography, Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginBg1 from "../images/login_src/bgg.png";
import Logo from "../images/login_src/logo.png";
import Glogo from "../images/login_src/Glogo.png";
import Slider from "./slider";
import { withFirebase } from "../Firebase";
import axios from "axios";
import { connect } from "react-redux";
import { postUser } from "../redux/action/user_action";
import { USER_URL } from "../utils/url";
import * as ROUTES from "../constant/route";
import firebase from "firebase";
const Login = ({ firebase, postUser }) => {
  const history = useHistory([]);
  const [userdata, setUserdata] = useState({
    email: "",
    password: "",
  });
  const [visible, setvisible] = useState(true);

  const handleChange = (e) => {
    const newUserdata = { ...userdata };
    newUserdata[e.target.name] = e.target.value;

    newUserdata.code !== "" && newUserdata.password !== ""
      ? setvisible(false)
      : setvisible(true);
    setUserdata(newUserdata);
  };

  const loginClick = () => {
    firebase
      .doSignInWithEmailAndPassword(userdata.email, userdata.password)
      .then((user) => {
        return {
          name: user.displayName,
          image: user.photoURL,
          email: userdata.email,
          contact: user.phoneNumber,
        };
      })
      .then((fuser) => {
        console.log(fuser);
        postUser(fuser);
        history.push(ROUTES.LANDING);
      });
  };
  const googleClick = (e) => {
    // firebase
    //   .googleSignIn()
    //   .then((result) => {
    //     console.log(result);
    //     let user = {
    //       name: result.user.displayName,
    //       email: result.user.email,
    //       image: result.user.photoURL,
    //       contact: result.user.phoneNumber | "",
    //     };
    //     console.log(user);
    //     postUser(user);
    //     history.push(ROUTES.LANDING);
    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box flexGrow="1" style={{ backgroundColor: "#F18905", height: 300 }}>
        <img src={Logo} style={{ height: 100, width: 200, marginTop: 30 }} />
        <img
          src={LoginBg1}
          style={{ height: 250, width: 380, marginTop: -82 }}
        />
        <Slider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#EFF1F1" }}
      >
        <Card
          style={{
            padding: 10,
            height: 230,
            width: 350,
            marginTop: 15,
          }}
        >
          <Box style={{ marginTop: 30 }}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              value={userdata.email}
              onChange={handleChange}
              style={{ width: 350 }}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              name="password"
              value={userdata.password}
              onChange={handleChange}
              style={{ width: 350, marginTop: 15 }}
            />
            <Button
              variant="outlined"
              disabled={visible}
              onClick={loginClick}
              style={{ width: 350, marginTop: 25 }}
            >
              Login
            </Button>
          </Box>
        </Card>
        <Box style={{ marginTop: 15 }}>
          <Typography>Or</Typography>

          {/* <Button
            variant="outlined"
            style={{ width: 350, marginTop: 15 }}
            onClick={() => googleClick()}
          >
            <img
              src={Glogo}
              style={{
                height: 30,
                width: 50,
                borderTopLeftRadius: 50,
                borderTopRightRadius: 50,
                borderBottomLeftRadius: 50,
                borderBottomRightRadius: 50,
              }}
            />

            <Typography style={{ marginLeft: 5 }}> Google </Typography>
            <Typography style={{ marginLeft: 5 }}>SignIn</Typography>
          </Button> */}
        </Box>

        <Box>
          <Box
            display="flex"
            flexDirection="row"
            style={{ marginLeft: 80, marginTop: 10 }}
          >
            <Typography style={{ marginTop: 6 }}>
              You dont have account
            </Typography>
            <Button onClick={() => history.push(ROUTES.SIGN_UP)}>SignUp</Button>
          </Box>
          <Typography style={{ marginTop: 2 }}>Forgot Password</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postUser: (guser) => dispatch(postUser(guser)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(Login));
