import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import Box from "@mui/material/Box";
import TestMap from "./allinOneMap/testmap";
import ListView from "./listView/index";
import Setting from "./setting";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import BottomNav from "./buttomNav";
import { getdevice } from "../redux/action/device_action";
import { getAuthUser } from "../redux/action/authuser";
const Devicelist = ({ devices, authuser, getdevice, getAuthUser }) => {
  const [bottomnav, setBottomnav] = useState();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("User"));

  useEffect(() => {
    getAuthUser(user.email);
  }, []);

  useEffect(() => {
    setBottomnav(1);
  }, []);
  return (
    <Box>
      {authuser ? (
        <Box style={{ height: 700 }}>
          {bottomnav == 1 && "undefined" ? <ListView /> : null}
          {bottomnav == 2 ? <TestMap authuser={authuser} /> : null}
          {bottomnav == 3 ? <TestMap /> : null}
          {bottomnav == 4 ? <TestMap /> : null}
          {bottomnav == 5 ? <Setting /> : null}
        </Box>
      ) : (
        "Failed To load Authuser OR Your dont have any assigned device"
      )}

      <Box>
        <BottomNav setBottomnav={setBottomnav} />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAuthUser: (user) => dispatch(getAuthUser(user)),
    getdevice: (id) => dispatch(getdevice(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(Devicelist));
