import React, { useEffect, useState } from "react";
import { Box, Typography, Card, Button, Icon } from "@mui/material";
import { withFirebase } from "../../Firebase";
import AllinoneInitmap from "./initmap";
const TestMap = ({ firebase, authuser }) => {
  const [userDevices, setuserDevices] = useState([]);

  useEffect(() => {
    const ref = firebase.userDevices(authuser._id);
    ref.on("child_added", (snapshot) => {
      const devices = [];
      // console.log(snapshot.val());

      // setuserDevices(snapshot.val());

      snapshot.forEach((childsnapshot) => {
        devices.push(childsnapshot.val());
        setuserDevices(devices);
      });
    });
  }, []);

  // console.log(userDevices);

  return (
    <Box>
      {userDevices != null ? <AllinoneInitmap devices={userDevices} /> : null}
    </Box>
  );
};

export default withFirebase(TestMap);
