import React from "react";
import { withFirebase } from "../../Firebase";
import { Box, Typography, Card, Button, Icon } from "@mui/material";
import TestMap from "../testmap";
import ListView from "../listView";

import { connect } from "react-redux";
import * as ROUTES from "../../constant/route";
import { useHistory } from "react-router";
import SettingControl from "./settingcontroll";
const Setting = ({ firebase }) => {
  const history = useHistory();
  const handleLogout = () => {
    firebase.doSignOut();
    localStorage.removeItem("User");
    history.push(ROUTES.LANDING);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        style={{ backgroundColor: "#F18905", height: 300, width: "100vw" }}
      >
        <Typography style={{ padding: 10, marginTop: 50 }} variant="h4">
          Setting Panel
        </Typography>
      </Box>
      <Box>
        <SettingControl />
      </Box>
    </Box>
  );
};

export default withFirebase(Setting);
