import React from "react";
import { Box, Typography } from "@mui/material";

const ReportTable = ({ Ddata }) => {
  const TimeConverter = (time) => {
    let t = time.split(":");
    let hour = t[0];
    let min = t[1];
    // console.log(hour[0]);
    // console.log(hour[1]);

    const lPad = (num, size) => {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    };

    if (hour > 12) {
      return lPad(hour % 12, 2) + ":" + min + " PM";
    } else {
      return hour + ":" + min + " AM";
    }
  };

  return (
    <Box display="flex" flexDirection="column" style={{ marginTop: 120 }}>
      <Box
        postion="Fixed"
        display="flex"
        flexDirection="row"
        style={{
          backgroundColor: "#3DB2FF",
          height: 50,
          alignItems: "center",
        }}
      >
        <Typography sx={{ flexGrow: "1" }}>Hour</Typography>
        <Typography sx={{ flexGrow: "1" }}>Time</Typography>
        <Typography sx={{ flexGrow: "1" }}>Distanse</Typography>
      </Box>

      {Ddata.map((item, index) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            key={index}
            style={{
              marginTop: 2,
              backgroundColor: "#FFF5DE",
              height: 50,
              alignItems: "center",
            }}
          >
            <Box flexGrow="1" style={{ marginRight: 5 }}>
              <Typography>{item._id}</Typography>
            </Box>

            <Box flexGrow="1">
              <Typography>{TimeConverter(item.start)}</Typography>
              <Typography>{TimeConverter(item.end)}</Typography>
            </Box>

            <Box flexGrow="1" style={{ marginLeft: 5 }}>
              <Typography>
                {item.distance == 0 ? "0.00" : item.distance} km
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ReportTable;
