import React from "react";
import { Box, Typography, Card, Button, Icon } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MessageIcon from "@mui/icons-material/Message";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VibrationIcon from "@mui/icons-material/Vibration";
import axios from "axios";
const Poweroption = ({ power }) => {
  const powerONmessage = "sms://" + power.device_sim_number + "/?body= 9410000";
  const powerOFFmessage =
    "sms://" + power.device_sim_number + "/?body= 9400000";
  const Mapviewmessage = "sms://" + power.device_sim_number + "/?body= 6690000";

  const controlPower = (e) => {
    console.log(e);
    let data = {
      _id: power._id,
      device_id: power.id,
      command_type: "S20",
      center_number: power.center_number,
      power: e,
    };

    axios
      .post("https://sultantracker.com/api/api/commands", data)
      .then((response) => {
        console.log(response.data);
        alert("  Command will executed sucessfully");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box style={{ padding: 20, marginTop: -150 }}>
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          style={{ height: 500, margin: 5 }}
        >
          <Button
            style={{
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            onClick={() => controlPower("ON")}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 205 }}
            >
              <Icon style={{ height: 50, marginTop: 15 }}>
                <PowerSettingsNewIcon />
              </Icon>
              <Typography>Power On</Typography>
            </Box>
          </Button>

          <Button
            style={{
              marginTop: 5,
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            onClick={() => controlPower("OFF")}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 200 }}
            >
              <Icon style={{ height: 50, marginTop: 15 }}>
                <PowerSettingsNewIcon />
              </Icon>
              <Typography>Power OFF</Typography>
            </Box>
          </Button>

          <Button
            style={{
              marginTop: 5,
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            // href="tel:+1-800-555-1234"
            // href="sms://{+14035550185}/?body= 6699000 "
            href={powerONmessage}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 168 }}
            >
              <Icon style={{ height: 50, marginTop: 20 }}>
                <MessageIcon />
              </Icon>
              <Typography>Power ON SMS</Typography>
            </Box>
          </Button>

          <Button
            style={{
              marginTop: 5,
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            href={powerOFFmessage}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 162 }}
            >
              <Icon style={{ height: 50, marginTop: 20 }}>
                <MessageIcon />
              </Icon>
              <Typography>Power OFF SMS</Typography>
            </Box>
          </Button>

          <Button
            style={{
              marginTop: 5,
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
            href={Mapviewmessage}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 140 }}
            >
              <Icon style={{ height: 50, marginTop: 15 }}>
                <MailOutlineIcon />
              </Icon>
              <Typography>Get Location SMS</Typography>
            </Box>
          </Button>

          <Button
            style={{
              marginTop: 5,
              height: 50,
              width: "100%",
              backgroundColor: "wheat",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ marginRight: 120 }}
            >
              <Icon style={{ height: 50, marginTop: 15 }}>
                <VibrationIcon />
              </Icon>
              <Typography>Vibration Alarm On</Typography>
            </Box>
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default Poweroption;
