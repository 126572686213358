import React from "react";
import Login from "./login";
import Home from "./home";
import SingUp from "./singup";
import Devicelist from "./devicelist";
import TestMap from "./allinOneMap/testmap";
import { withFirebase } from "../Firebase";
import Dailyreport from "./dailyreport/dailyreport";
import Setting from "../component/setting/";
import Initmap from "./initmap";
import Report from "./report";
import Controll from "./controllBox/controll";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";

const Main = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/singup" component={SingUp} />
          <Route exact path="/devicelist" component={Devicelist} />
          <Route exact path="/testmap" component={TestMap} />
          <Route exact path="/setting" component={Setting} />
          <Route exact path="/initmap" component={Initmap} />
          <Route exact path="/report" component={Report} />
          <Route exact path="/dailyreport" component={Dailyreport} />
          <Route exact path="/controll" component={Controll} />
        </Switch>
      </Router>
    </div>
  );
};

export default withRouter(withFirebase(Main));
