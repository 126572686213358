import { combineReducers } from "redux";
import auth_user_reducers from "./auth_user_reducer";
import user_reducer from "../reducers/user_reducer";
import device_reducer from "./device_reducer";
import sharedDevicesReducer from "./sharedvehicale_reducer";
const reducers = combineReducers({
  authuser: auth_user_reducers,
  users: user_reducer,
  devices: device_reducer,
  shared_vehicale: sharedDevicesReducer,
});

export default reducers;
