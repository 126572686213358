import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Car from "../../images/login_src/car.png";
import Footer from "./footer";
// import Car from "../../images/Icon/car.png";
const Container = ({ data }) => {
  // const [icon, setIcon] = useState([]);
  // const checkDevice = () => {
  //   if (data.geo.acc) {
  //     const acc = data.geo.acc;
  //     switch (data.vehicle_type) {
  //       case 1:
  //         return acc == "ON" ? Car : Car;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setIcon((old) => checkDevice());
  // }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        height: 140,
        backgroundColor: "white",
        margin: 5,
      }}
    >
      <Box display="flex" flexDirection="row" style={{ width: "100%" }}>
        <Box alignItems="flex-end" sx={{ border: 1 }}>
          <img
            src={Car}
            style={{
              height: 50,
              width: 100,
            }}
          />
        </Box>
        <Box sx={{ border: 1, width: "100%" }}>
          <Box display="flex" flexDirection="row">
            <Typography style={{ padding: 5 }}>
              {data.registration_number}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" style={{ marginTop: -15 }}>
            <Typography variant="body" style={{ padding: 5 }}>
              Engine Status :
            </Typography>
            <Typography variant="body" style={{ padding: 5 }}>
              {data.geo == null ? "Undefined" : data.geo.acc}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" style={{ marginTop: -10 }}>
            <Typography variant="body" style={{ padding: 5 }}>
              Speed:
            </Typography>
            <Typography variant="body" style={{ padding: 5 }}>
              {data.geo == null ? "Undefined" : data.geo.speed}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" style={{ marginTop: -10 }}>
            <Typography variant="body" style={{ padding: 5 }}>
              Location:
            </Typography>
            <Typography variant="body" style={{ padding: 5 }}>
              Unnamed Road
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ border: 1 }}>
        <Footer data={data} />
      </Box>
    </Box>
  );
};

export default Container;
