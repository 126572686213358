import React from "react";
import { Box, Button, Icon, IconButton } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import SettingsIcon from "@mui/icons-material/Settings";
import ErrorIcon from "@mui/icons-material/Error";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import * as ROUTES from "../../constant/route";
import { useHistory } from "react-router";
const Footer = ({ data }) => {
  const history = useHistory();
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    <Box style={{ margin: 5 }}>
      <Box display="flex" flexDirection="row">
        <Button
          style={{ margin: 2 }}
          onClick={() =>
            history.push({
              pathname: ROUTES.INIT_MAP,
              state: data,
            })
          }
        >
          <Icon style={{ marginTop: -5, height: 30 }}>
            <RoomIcon />
          </Icon>
          Map
        </Button>
        <Button
          style={{ margin: 2 }}
          onClick={() =>
            history.push({
              pathname: ROUTES.CONTROLL,
              state: data,
            })
          }
        >
          <Icon style={{ marginTop: -5, height: 30 }}>
            <SettingsIcon />
          </Icon>
          Controll
        </Button>
        <Button style={{ margin: 2 }}>
          <Icon style={{ marginTop: -5, height: 30 }}>
            <ErrorIcon />
          </Icon>
          Details
        </Button>
        <Button {...bindTrigger(popupState)}>
          <Icon style={{ marginTop: -5, height: 30 }}>
            <MoreVertIcon />
          </Icon>
        </Button>
        <Menu {...bindMenu(popupState)}>
          <MenuItem
            onClick={() =>
              history.push({
                pathname: ROUTES.DAILY_REPORT,
                state: data,
              })
            }
          >
            Daily Report
          </MenuItem>
          <MenuItem
            onClick={() =>
              history.push({
                pathname: ROUTES.REPORT,
                state: data,
              })
            }
          >
            Monthly Report
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Footer;
