import React from "react";
import { Box, Typography } from "@mui/material";

const getTime = (seconds) => {
  let hr = null;
  let min = null;
  let sec = null;
  if (seconds >= 3600) {
    hr = parseInt(seconds / 3600);
    let remaining = seconds - hr * 3600;
    min = parseInt(remaining / 60);

    return hr + " hrs " + min + " min";
  } else {
    min = parseInt(seconds / 60);
    sec = seconds - min * 60;

    return min + " min " + sec + " sec";
  }
};

const TimeConverter = (time) => {
  if (time == "-") {
    let undefined = "-";
    return undefined;
  }
  let t = time.split(":");
  let hour = t[0];
  let min = t[1];
  // console.log(hour[0]);
  // console.log(hour[1]);

  const lPad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  if (hour > 12) {
    return lPad(hour % 12, 2) + ":" + min + " PM";
  } else {
    return hour + ":" + min + " AM";
  }
};

const StatusTable = ({ tripdata }) => {
  console.log("Dailyreport ", tripdata);

  return (
    <Box display="flex" flexDirection="column" style={{ marginTop: 120 }}>
      <Box
        postion="Fixed"
        display="flex"
        flexDirection="row"
        style={{
          backgroundColor: "#3DB2FF",
          height: 50,
          alignItems: "center",
        }}
      >
        <Typography sx={{ flexGrow: "1" }}>Status</Typography>
        <Typography sx={{ flexGrow: "1" }}>Time</Typography>
        <Typography sx={{ flexGrow: "1" }}>Duration</Typography>
        <Typography sx={{ flexGrow: "1" }}>Distanse</Typography>
      </Box>

      {tripdata.map((item, index) => {
        return (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ padding: 5, height: "100%" }}
          >
            <Box style={{ marginLeft: 20 }}>
              <Typography>{item.status}</Typography>
            </Box>
            <Box flexGrow="1" style={{ marginLeft: 30 }}>
              <Typography>
                {item.start == "-" ? "-" : TimeConverter(item.start)}
              </Typography>
              <Typography>
                {item.start == "-" ? "-" : TimeConverter(item.end)}
              </Typography>
            </Box>
            <Box flexGrow="1">
              <Typography>{getTime(item.duration)}</Typography>
            </Box>
            <Box>
              <Typography style={{ marginRight: 30 }}>
                {item.distance}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatusTable;
