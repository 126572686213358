import React, { useState } from "react";
import { Box, TextField, Button, Typography, Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginBg1 from "../images/login_src/bgg.png";
import Logo from "../images/login_src/logo.png";
import Glogo from "../images/login_src/Glogo.png";
import Slider from "./slider";
import { withFirebase } from "../Firebase";
import axios from "axios";
import { connect } from "react-redux";
import { postUser } from "../redux/action/user_action";
import { USER_URL } from "../utils/url";
import * as ROUTES from "../constant/route";
import firebase from "firebase";
const SignUp = ({ firebase, postUser }) => {
  const history = useHistory([]);
  const [userdata, setUserdata] = useState({
    email: "",
    password: "",
  });
  const [visible, setvisible] = useState(true);

  const handleChange = (e) => {
    const newUserdata = { ...userdata };
    newUserdata[e.target.name] = e.target.value;

    newUserdata.code !== "" && newUserdata.password !== ""
      ? setvisible(false)
      : setvisible(true);
    setUserdata(newUserdata);
  };

  const loginClick = () => {
    firebase
      .createUserWithEmailAndPassword(userdata.email, userdata.password)
      .then((result) => {
        firebase.doSendEmailVerification();
        alert("check your email");
      });
  };
  const googleClick = (e) => {
    // firebase
    //   .googleSignIn()
    //   .then((result) => {
    //     console.log(result);
    //     let user = {
    //       name: result.user.displayName,
    //       email: result.user.email,
    //       image: result.user.photoURL,
    //       contact: result.user.phoneNumber | "",
    //     };
    //     console.log(user);
    //     postUser(user);
    //     history.push(ROUTES.LANDING);
    //   })
    //   .catch((err) => console.log(err));
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box flexGrow="1" style={{ backgroundColor: "#F18905", height: 300 }}>
        <img src={Logo} style={{ height: 100, width: 200, marginTop: 30 }} />
        <img
          src={LoginBg1}
          style={{ height: 250, width: 380, marginTop: -82 }}
        />
        <Slider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: "#EFF1F1" }}
      >
        <Card
          style={{
            padding: 10,
            height: 230,
            width: 350,
            marginTop: 80,
          }}
        >
          <Box style={{ marginTop: 30 }}>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              value={userdata.email}
              onChange={handleChange}
              style={{ width: 350 }}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              name="password"
              value={userdata.password}
              onChange={handleChange}
              style={{ width: 350, marginTop: 15 }}
            />
            <Button
              variant="outlined"
              disabled={visible}
              onClick={loginClick}
              style={{ width: 350, marginTop: 25 }}
            >
              SignUp
            </Button>
          </Box>
        </Card>

        <Box style={{ marginTop: 50 }}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ marginLeft: 30, marginTop: 20 }}
          >
            <Typography style={{ marginTop: 6 }}>
              Already have an account?
            </Typography>
            <Button onClick={() => history.push(ROUTES.LOGIN)}>Login</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postUser: (guser) => dispatch(postUser(guser)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFirebase(SignUp));
