// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import { withFirebase } from "../Firebase";
// import MainPage from "./main";
// import LandingPage from "./login";
// import EmailVerifiedPage from "./EmailVarified";

// import { connect } from "react-redux";
// import { getAuthUser } from "../redux/action/authuser";

// class Home extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { authUser: null };
//   }

//   loadComponent = () => {
//     this.state.uid ? (
//       <MainPage />
//     ) : (
//       <LandingPage authUser={this.state.authUser} />
//     );
//     // if (this.state.uid) {
//     //   // if (this.state.emailVerified) {
//     //   return <MainPage />;
//     //   // } else {
//     //   //   return <EmailVerifiedPage />;
//     //   // }
//     // } else {
//     //   return <LandingPage authUser={this.state.authUser} />;
//     // }
//   };

//   componentDidMount() {
//     // this.listener = this.props.getttAuthUser(this.props.firebase.currentUser());
//     this.listener = this.props.firebase.auth.onAuthStateChanged((user) => {
//       this.props.getAuthUser(user, this.props.firebase);
//       //this.authUser = user;
//     });

//     // this.props.firebase.messaging
//     //   .requestPermission()
//     //   .then(() => this.props.firebase.messaging.getToken())
//     //   .then((token) => console.log("Token is", token))
//     //   .catch((err) => console.log(err));

//     // Notification.requestPermission()
//     //   .then(() => this.props.firebase.messaging.getToken())
//     //   .then((token) => console.log("Token is", token))
//     //   .catch((err) => console.log(err));
//   }

//   componentWillUnmount() {
//     // console.log("Component Will UnMount Called in App");
//     this.listener();
//   }

//   render() {
//     return <LandingPage />;
//   }
// }

// const mapStateToProps = (state) => {
//   console.log(state);
//   return {
//     ...state,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getAuthUser: (firebaseUser, firebase) =>
//       dispatch(getAuthUser(firebaseUser, firebase)),
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(withFirebase(Home)));

import React, { useEffect } from "react";
import { connect } from "react-redux";

import Login from "./login";
import Devicelist from "./devicelist";
import firebase from "firebase";
const Home = ({ users, history }) => {
  // const off = (listener) => {
  //   listener();
  // };

  // useEffect(() => {
  //   // firebase.auth().onAuthStateChanged((user) => {
  //   //   if (user) {
  //   //     history.push(ROUTES.DEVICE_LIST);
  //   //   } else {
  //   //     console.log("Else Called");
  //   //     history.push(ROUTES.LOGIN);
  //   //   }
  //   // });
  //   // console.log(uuu);
  //   // return off(uuu);
  // }, []);
  const user = JSON.parse(localStorage.getItem("User"));

  return <div>{user == null ? <Login /> : <Devicelist />}</div>;
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Home);
