import React from "react";
import { GoogleMap, LoadScript, TrafficLayer } from "@react-google-maps/api";
import { Box, Typography, Card, Button, Icon } from "@mui/material";
import MyMarker from "../allinOneMap/marker";

const AllinoneInitmap = ({ devices }) => {
  console.log(devices);
  const containerStyle = {
    height: "100vh",
    width: "100vw",
  };
  const getCenter = {
    lat: 23.777176,
    lng: 90.399452,
  };

  return (
    <Box>
      <LoadScript googleMapsApiKey="AIzaSyADdjjHQv-tiqmz9aD1DFU_LsZOBx2n7z8">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={getCenter}
          zoom={6.5}
        >
          {devices != null
            ? devices.map((device, index) =>
                device.geo ? <MyMarker key={index} device={device} /> : null
              )
            : null}
          <TrafficLayer />
        </GoogleMap>
      </LoadScript>
    </Box>
  );
};

export default AllinoneInitmap;
