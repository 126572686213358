import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Paper, Typography } from "@mui/material";
import Report from "./report";
import StatusTable from "./status";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import axios from "axios";

import { grey, red } from "@mui/material/colors";
import { DAILYDATA } from "../../utils/url";
import { useLocation } from "react-router-dom";
const DailyReport = () => {
  const location = useLocation();
  const device = location.state;

  const [reportvisible, setReportvisible] = useState(true);
  const [date, setValue] = React.useState(new Date());
  const [dailyData, setDailyData] = useState([]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  // const Device = JSON.parse(localStorage.getItem("Device"));

  const fetchDailyData = () => {
    let data = {
      device_id: device.id,
      device_type: device.vehicle_type,
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };
    console.log(data);

    axios
      .post(DAILYDATA, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setDailyData(response.data);
      })

      .catch((Err) => {
        console.log(Err);
      });
  };

  useEffect(() => {
    fetchDailyData();
  }, [date]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: "100vw", height: "100vh" }}
    >
      <Box position="Fixed" style={{ width: "100%", backgroundColor: "white" }}>
        <Box style={{ width: 200, margin: 10 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                label="Pick Up Your Date "
                inputFormat="MM/dd/yyyy"
                value={date}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ backgroundColor: "#FFE6DE", padding: 10, margin: 5 }}
        >
          <Button
            //   style={{ marginLeft: 400 }}
            onClick={() => {
              setReportvisible(true);
            }}
          >
            Daily Report
          </Button>
          <Button
            //   style={{ marginLeft: 500 }}
            onClick={() => {
              setReportvisible(false);
            }}
          >
            Trip Report
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        flexGrow="1"
        style={{ height: "100%", width: "100%" }}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%",
            marginTop: 5,
            padding: 10,
          }}
        >
          {reportvisible && dailyData.hourly_report != null ? (
            <Report Ddata={dailyData.hourly_report} />
          ) : (
            !reportvisible
          )}
          {!reportvisible ? (
            <StatusTable tripdata={dailyData.trip_report} />
          ) : (
            reportvisible
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default DailyReport;
