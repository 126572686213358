import axios from "axios";
import { USER_URL } from "../../utils/url";
import { SET_USER } from "../constant/action_type";
const bbb = {};
export const setuser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const postUser = (guser) => {
  return (dispatch) => {
    return axios
      .post(USER_URL, guser)
      .then((response) => {
        console.log(response.data);

        let dbUser = response.data;
        bbb["name"] = dbUser.name;
        bbb["is_admin"] = dbUser.is_admin;
        bbb["contact"] = dbUser.contact;
        bbb["email"] = dbUser.email;
        bbb["image"] = dbUser.image;
        bbb["webuid"] = dbUser._id;
        bbb["organization_name"] = dbUser.organization_name;
        bbb["address"] = dbUser.address;
        bbb["token"] = dbUser.token;
        localStorage.setItem("User", JSON.stringify(bbb));
        dispatch(setuser(bbb));
      })
      .catch((err) => {
        throw err;
      });
  };
};
