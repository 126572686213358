import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Button,
  Paper,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { MONTHLY_LOCATIONS_URL } from "../utils/url";
// import { MONTHLY_FILE_URL } from "../utils/url";
import { connect } from "react-redux";
import dateformat from "dateformat";
import Nav from "../component/nav";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ConstructionOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { grey, red } from "@mui/material/colors";
import ReportTable from "./monthlyreport";
import { useLocation } from "react-router-dom";
const MonthlyReport = () => {
  const location = useLocation();
  const device = location.state;
  const [date, setDate] = useState(new Date());
  const [monthlydata, setMonthlyData] = useState([]);

  console.log(device);

  const fetchMonthlyData = () => {
    let data = {
      device_id: device.id,
      year: date.getFullYear(),
      month: date.getMonth(),
    };
    axios
      .post(MONTHLY_LOCATIONS_URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => setMonthlyData(response.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchMonthlyData();
  }, [date]);

  const prevClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() - 1);
      return bbb;
    });
  };

  const nextClick = () => {
    setDate((oldDate) => {
      const bbb = new Date(oldDate);
      bbb.setMonth(bbb.getMonth() + 1);
      return bbb;
    });
  };

  // const downloadFile = () => {
  //   let data = {
  //     device_id: Device.id,
  //     year: date.getFullYear(),
  //     month: date.getMonth(),
  //   };
  //   axios
  //     .post(MONTHLY_FILE_URL, data, { responseType: "blob" })
  //     .then((response) => {
  //       const file = new Blob([response.data], { type: "application/pdf" });
  //       const fileURL = URL.createObjectURL(file);
  //       window.open(fileURL);
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: grey[300],
      }}
    >
      <Box
        position="Fixed"
        style={{
          padding: 10,
          height: 50,
          width: "100%",
          backgroundColor: grey[500],
        }}
      >
        <Paper style={{ marginRight: 20 }}>
          <Box display="flex">
            <IconButton onClick={prevClick}>
              <ArrowBackIos />
            </IconButton>
            <Box
              display="flex"
              flexGrow="1"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body2">
                Report on {dateformat(date, "mmmm-yyyy")}
              </Typography>
            </Box>
            <IconButton onClick={nextClick}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Paper>
      </Box>

      <Box display="flex" flexGrow="1">
        <Paper
          style={{
            width: "98%",
            backgroundColor: grey[500],
            marginTop: 60,
            padding: 10,
          }}
        >
          <ReportTable monthlydata={monthlydata} />
        </Paper>
      </Box>
    </Box>
    // </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     getdevice: (id) => dispatch(getdevice(id)),
//   };
// };

export default connect(mapStateToProps)(MonthlyReport);
