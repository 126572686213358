import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Carimg from "../images/login_src/car.png";
import Cngimg from "../images/login_src/cng.png";
import Scootyimg from "../images/login_src/scooty.png";

export default class MySlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div
        id="marginTop"
        style={{
          width: "100%",
          height: 195,
          overflow: "hidden",
          marginTop: -200,
        }}
      >
        <Slider {...settings}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={Carimg}
                style={{ height: 180, width: "100%", marginTop: 20 }}
              />
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={Cngimg}
                style={{ height: 180, width: "100%", marginTop: 20 }}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={Scootyimg}
                style={{ height: 180, width: "100%", marginTop: 20 }}
              />
            </div>
          </div>
          <div></div>
        </Slider>
      </div>
    );
  }
}
