import axios from "axios";

import { AUTH_USER } from "../constant/action_type";
import { USER_URL } from "../../utils/url";
import firebase from "firebase";
const authUserAction = (data) => {
  return { type: AUTH_USER, payload: data };
};

export const getAuthUser = (email) => {
  return (dispatch) => {
    if (email) {
      axios
        .get(USER_URL + email)
        .then((response) => {
          dispatch(authUserAction(response.data));
          // AsyncStorage.setItem("authuser", response.data);
          console.log(response.data);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      dispatch(authUserAction(null));
    }
  };
};

// export const getAuthUser = (user, firebase) => {
//   return (dispatch) => {
//     if (user) {
//       const { uid, email, emailVerified } = user;
//       const bbb = {
//         uid: uid,
//         emailVerified: emailVerified,
//         email: email,
//         authToken: user.refreshToken,
//       };

//       firebase.idToken().then((idToken) => {
//         axios
//           .get(USER_URL + user.email, {
//             headers: { Authorization: "Bearer " + idToken },
//           })
//           .then((response) => {
//             let dbUser = response.data;
//             console.log(dbUser);
//             bbb["name"] = dbUser.name;
//             bbb["is_admin"] = dbUser.is_admin;
//             bbb["contact"] = dbUser.contact;
//             bbb["image"] = dbUser.image;
//             bbb["webuid"] = dbUser._id;
//             bbb["organization_name"] = dbUser.organization_name;
//             bbb["address"] = dbUser.address;
//             bbb["token"] = dbUser.token;

//             localStorage.setItem("authUser", JSON.stringify(bbb));
//             dispatch(authUserAction(bbb));
//           })
//           .catch((err) => {
//             throw err;
//           });
//       });
//     } else {
//       dispatch(authUserAction(null));
//     }
//   };
// };
