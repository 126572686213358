import React from "react";
import { Box, Button, Typography } from "@mui/material";
import useCollapse from "react-collapsed";
import dateFormat from "dateformat";
import { ArrowDropDownCircle, ArrowDropUpRounded } from "@mui/icons-material";
import { grey, red } from "@mui/material/colors";
const Collapse = ({ item, index }) => {
  console.log(item);
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    defaultExpanded: false,
  });

  const getTime = (seconds) => {
    let hr = null;
    let min = null;
    let sec = null;
    if (seconds >= 3600) {
      hr = parseInt(seconds / 3600);
      let remaining = seconds - hr * 3600;
      min = parseInt(remaining / 60);

      return hr + " hrs " + min + " min";
    } else {
      min = parseInt(seconds / 60);
      sec = seconds - min * 60;

      return min + " min " + sec + " sec";
    }
  };

  return (
    <Box display="flex" flexDirection="column" key={index}>
      <Box
        display="flex"
        flexDirection="row"
        {...getToggleProps({
          style: { height: 50, backgroundColor: "wheat", marginTop: 2 },
        })}
      >
        <Box
          justifyContent="flex-start"
          style={{
            backgroundColor: "#3DB2FF",
          }}
        >
          <Typography style={{ fontSize: 20, width: 50, marginTop: 10 }}>
            {item._id.day}
          </Typography>
        </Box>
        <Box flexGrow="1">
          <Typography style={{ marginLeft: 200, marginTop: 10 }}>
            {(item.distance / 1000).toFixed(2)}KM
          </Typography>
        </Box>

        <Box style={{ marginTop: 12, marginRight: 5 }}>
          {!isExpanded ? <ArrowDropDownCircle /> : <ArrowDropUpRounded />}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        {...getCollapseProps()}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box justifyContent="flex-start">
            <Typography style={{ padding: 5, margin: 5 }}>
              Start Time :
            </Typography>
          </Box>
          <Box>
            <Typography style={{ padding: 5, margin: 5 }}>
              {dateFormat(item.start_time, "hh:MM:TT")}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box justifyContent="flex-start">
            <Typography style={{ padding: 5, margin: 5 }}>
              Stop Time :
            </Typography>
          </Box>
          <Box>
            <Typography style={{ padding: 5, margin: 5 }}>
              {dateFormat(item.end, "hh:MM:TT")}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box justifyContent="flex-start">
            <Typography style={{ padding: 5, margin: 5 }}>
              Idle Time :
            </Typography>
          </Box>
          <Box>
            <Typography style={{ padding: 5, margin: 5 }}>
              {getTime(item.idle_time)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box justifyContent="flex-start">
            <Typography style={{ padding: 5, margin: 5 }}>
              Running Time :
            </Typography>
          </Box>
          <Box>
            <Typography style={{ padding: 5, margin: 5 }}>
              {getTime(item.running_time)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography style={{ padding: 5, margin: 5 }}>
              Congestion Time :
            </Typography>
          </Box>
          <Box style={{ padding: 5, margin: 5 }}>
            <Typography>{getTime(item.congestion_time)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Collapse;
