import React from "react";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
const Header = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: "#F18905", height: 120, width: "100vw" }}
    >
      <Box display="flex">
        <Typography
          alignItems="flex-end"
          variant="body"
          style={{ padding: 5, fontSize: 20 }}
        >
          Sultan Tracker
        </Typography>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <TextField
          id="outlined-basic"
          label="Device Id"
          variant="outlined"
          style={{ width: 350, backgroundColor: "white" }}
        />
      </Box>
    </Box>
  );
};

export default Header;
