import React from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import NavBar from "./nav";
import GoogleMap from "./gmap";
const Initmap = () => {
  const location = useLocation();
  const device = location.state;

  return (
    <Box style={{ height: 700 }}>
      <NavBar data={device} />

      {device.geo != null ? <GoogleMap device={device} /> : null}
    </Box>
  );
};

export default Initmap;
